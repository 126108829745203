import { ChangeDetectionStrategy, Component, HostBinding, OnInit, inject, input, signal } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sleep } from '@dd/esm-common';
import { CreateContactMessageInput, contactMessageValidators } from '@plutus-realty/models/validators/contact-message';
import { TRPC_API } from '../../api/trpc.api';
import { COMMON_IMPORTS, FORM_IMPORTS } from '../../common-imports';
import { zodValidator } from '../../utilities/zod-validator';
import { SnackbarComponent } from '../notifications/snackbar';
import { ContactFormAcknowledgementComponent } from './acknowledgement.snackbar';

@Component({
  standalone: true,
  selector: 'contact-form',
  templateUrl: './contact-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    COMMON_IMPORTS,
    FORM_IMPORTS
  ]
})
export class ContactFormComponent implements OnInit {
  private readonly trpc = inject(TRPC_API);
  private readonly snackbar = inject(MatSnackBar);

  @HostBinding() private readonly class = 'block';

  public readonly subject$ = input<string>('', { alias: 'subject' });

  protected readonly submitting$ = signal(false);

  protected readonly maxlengths = {
    name: 64,
    email: 64,
    subject: 64,
    message: 512
  } as const;

  protected readonly formId = 'contact-form';
  protected readonly form = new FormGroup({
    name: new FormControl('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.maxLength(this.maxlengths.name)
      ]
    }),
    email: new FormControl('', {
      validators: [
        Validators.email,
        Validators.maxLength(this.maxlengths.email)
      ]
    }),
    phone: new FormControl('', {
      nonNullable: true,
      validators: [
        Validators.required,
        zodValidator(contactMessageValidators.phone)
      ]
    }),
    subject: new FormControl('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.maxLength(this.maxlengths.subject)
      ]
    }),
    message: new FormControl<string>('', {
      validators: [
        Validators.maxLength(this.maxlengths.message)
      ]
    }),
  }, { updateOn: 'submit' });

  async submit(fgd: FormGroupDirective) {
    if (!this.form.valid)
      return;

    try {
      this.submitting$.set(true);

      const value = this.form.getRawValue();

      const input: CreateContactMessageInput = {
        email: value.email || null,
        message: value.message || null,
        name: value.name,
        phone: value.phone,
        subject: value.subject
      };

      await this.trpc.contactMessage.create.mutate(input);

      ContactFormAcknowledgementComponent.open(this.snackbar);
      fgd.resetForm();
      this.reset();
    }
    catch (e) {
      SnackbarComponent.forError(this.snackbar, e);
      throw e;
    }
    finally {
      this.submitting$.set(false);
    }
  }

  async reset() {
    await sleep();
    const subject = this.subject$();
    if (subject)
      this.form.patchValue({ subject: subject });
  }

  ngOnInit(): void {
    this.reset();
  }

}