import { Injectable, inject } from '@angular/core';
import { getAuth } from 'firebase/auth';
import { CLIENT_CONFIG } from '../dependency-injection';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private readonly clientConfig = inject(CLIENT_CONFIG);
  private readonly factories = this.clientConfig.firebase.factories;
  private readonly options = this.clientConfig.firebase.options;

  public readonly app = this.getInitializedApp();
  public readonly auth = getAuth(this.app);

  private getInitializedApp() {
    const app = this.factories.app(this.options);
    this.factories.auth(app);
    return app;
  }
}