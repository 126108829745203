import { DateTime } from 'luxon';
import { Node } from './node';

export const USER_SETUP_FLAG = 'settingUp';

export interface User extends Node {
  uid: string;
  disabled: boolean;

  displayName: string | null;
  photoUrl: string | null;

  email: string | null;
  phoneNumber: string | null;
}

export interface UserClaims {
  id: string;
}

export interface DeviceToken {
  userId: string;
  token: string;
  updated: DateTime;
}