import { DestroyRef, INJECTOR, Injectable, inject, isDevMode } from '@angular/core';
import { getSHA256Hash } from '@dd/angular-common';
import { Store } from '@ngrx/store';
import { oauthCodeGenerator } from '@plutus-realty/models/oauth';
import { GoogleAuthProvider, UserCredential, getAdditionalUserInfo, getIdToken, getIdTokenResult, getRedirectResult, signInWithCustomToken, signInWithRedirect } from 'firebase/auth';
import { firstValueFrom, retry, switchMap, timer } from 'rxjs';
import { OAuthApi } from '../api/oauth';
import { CLIENT_CONFIG } from '../dependency-injection';
import { authActions, authFeature, checkIfSettingUp } from '../state/auth';
import { isBrowser } from '../utilities/platform-type';
import { CookieService } from './cookies';
import { FirebaseService } from './firebase';

export const RETURN_URL_KEY = 'returnUrl';
export const CODE_VERIFIER_KEY = 'code_verifier';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() {
    if (!isBrowser())
      return;

    this.init();
  }

  private readonly store = inject(Store);
  private readonly firebase = inject(FirebaseService);
  private readonly dRef = inject(DestroyRef);
  private readonly devMode = isDevMode();
  private readonly injector = inject(INJECTOR);
  private readonly sso = inject(CLIENT_CONFIG).sso;
  private readonly cookies = inject(CookieService);

  private readonly user$ = this.store.selectSignal(authFeature.selectUser);

  readonly signIn = {
    google: () => {
      return signInWithRedirect(this.firebase.auth, new GoogleAuthProvider());
    },
    plutus: async (state?: string) => {
      const code = await codeChallenge.create();
      const api = this.injector.get(OAuthApi);
      api.authorize(code, state);
    },
    oauthCode: async (code: string, returnUrl: string | null) => {
      const verifier = codeChallenge.get();
      const api = this.injector.get(OAuthApi);

      const token = await api.token(code, verifier);
      const cred = await signInWithCustomToken(this.firebase.auth, token);

      await this.handleSignIn(cred, returnUrl);
    }
  } as const;

  async token() {
    const user = this.firebase.auth.currentUser;
    if (user)
      return getIdToken(user);
    return null;
  }

  async signOut() {
    await this.firebase.auth.signOut();
    if (this.sso)
      this.cookies.USER_ID.delete(this.sso.domain);
    this.store.dispatch(authActions.signOut({ revoked: false }));
  }
  async revokeToken() {
    await this.firebase.auth.signOut();
    if (this.sso)
      this.cookies.USER_ID.delete(this.sso.domain);
    this.store.dispatch(authActions.signOut({ revoked: true }));
  }

  private async init() {
    this.handleRedirect();
  }

  private handleRedirect() {
    getRedirectResult(this.firebase.auth)
      .then(u => {
        if (u) {
          const returnUrl = new URLSearchParams(location.search)
            .get(RETURN_URL_KEY);
          this.handleSignIn(u, returnUrl);
        }
      });
  }

  private async handleSignIn(credential: UserCredential, returnUrl: string | null) {
    const isSettingUp = await checkIfSettingUp.credential(credential);

    if (isSettingUp) {
      this.store.dispatch(authActions.settingUpAccount());

      await firstValueFrom(timer(3000).pipe(
        switchMap(async () => {
          const isSettingUp = await checkIfSettingUp.credential(credential, true);
          if (isSettingUp)
            throw new Error('user is setting up');
        }),
        retry(5)
      )).catch(async e => {
        await this.revokeToken();
        this.store.dispatch(authActions.accountSetupTimedOut());
        throw e;
      });

      await this.handleSignIn(credential, returnUrl);
    }
    else {
      const info = getAdditionalUserInfo(credential);
      const isNewUser = info?.isNewUser ?? false;

      const user = this.user$();
      if (!user)
        throw new Error('user should have been populated by this point');
      if (this.sso) {
        const info = await getIdTokenResult(credential.user);
        const expiry = info.expirationTime;
        this.cookies.USER_ID.write(user.id, expiry, this.sso.domain);
      }

      this.store.dispatch(authActions.signIn({ isNewUser, returnUrl }));
    }
  }

}

const codeChallenge = {
  create: async () => {
    const verifier = oauthCodeGenerator(64);
    const challenge = await getSHA256Hash(verifier);
    const method = 'S256';

    localStorage.setItem(CODE_VERIFIER_KEY, verifier);
    return { challenge, method };
  },
  get: () => {
    const verifier = localStorage.getItem(CODE_VERIFIER_KEY);
    if (!verifier)
      throw new Error(`'${CODE_VERIFIER_KEY}' not found in localStorage`);
    return verifier;
  }
} as const;
