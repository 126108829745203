import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from '@ngrx/store';
import { extractPermissionList, PermissionDefinitionDTO } from '@plutus-realty/models/permission-record';
import { USER_SETUP_FLAG, UserClaims } from '@plutus-realty/models/user';
import { User as FirebaseUser, getIdTokenResult, IdTokenResult, UserCredential } from 'firebase/auth';
import { produce } from 'immer';

export const authActions = createActionGroup({
  source: 'auth',
  events: {
    // 'id token updated': emptyProps(),
    'update permission definition': props<{ dto: PermissionDefinitionDTO; }>(),
    'update user': props<{
      payload: {
        user: AuthUser | null;
      };
    }>(),
    'sign in': props<{
      isNewUser: boolean;
      returnUrl: string | null;
    }>(),
    'sign out': props<{ revoked: boolean; }>(),
    'setting up account': emptyProps(),
    'account setup timed out': emptyProps()
  }
});

export interface AuthUser extends UserClaims {
  id: string;
  uid: string;
  disabled: boolean;

  displayName: string | null;
  photoUrl: string | null;

  email: string | null;
  phoneNumber: string | null;

  roles: Set<string>;
  permissions: Set<string>;
}

export interface AuthState {
  ready: boolean;
  user: AuthUser | null;
  permissions: ReadonlySet<string>;
}

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer<AuthState>(
    {
      ready: false,
      permissions: new Set(),
      user: null
    },

    on(authActions.updatePermissionDefinition, produce((state, { dto }) => {
      state.permissions = new Set(extractPermissionList(dto));
    })),

    on(authActions.updateUser, produce((state, { payload }) => {
      state.user = payload.user;
      state.ready = true;
    }))
  )
});


export const checkIfSettingUp = {
  tokenResult: (itr: IdTokenResult) => USER_SETUP_FLAG in itr.claims,
  credential: async (credential: UserCredential, forceRefresh = false) => {
    const itr = await getIdTokenResult(credential.user, forceRefresh);
    return checkIfSettingUp.tokenResult(itr);
  },
} as const;

export function mapTokenToUser(user: FirebaseUser, tokenResult: IdTokenResult) {
  const u: AuthUser = {
    disabled: false,
    displayName: user.displayName ?? 'New User',
    email: user.email,
    phoneNumber: user.phoneNumber,
    photoUrl: user.photoURL,
    uid: user.uid,

    id: tokenResult.claims['id'] as string,

    permissions: new Set<string>(),
    roles: new Set<string>()
  };
  return u;
}