import { TypeOf, z } from 'zod';
import { patterns } from './patterns';

const types = {
  name: z.string().trim()
    .max(24),
  phone: z.string().trim()
    .length(10)
    .regex(patterns.phone.short),
  email: z.string().trim()
    .max(64)
    .email(),
  subject: z.string().trim().max(64),
  message: z.string().trim().max(512)
} as const;

const inputs = {
  create: z.strictObject({
    name: types.name,
    email: types.email.nullish().default(null),
    phone: types.phone,
    subject: types.subject,
    message: types.message.nullish().default(null)
  })
} as const;

export const contactMessageValidators = { ...types, inputs } as const;

export type CreateContactMessageInput = TypeOf<typeof inputs['create']>;