import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ProblemDetails } from '@plutus-realty/models/problem-details';

export interface NotificationSnackbarData {
  message: string;
}

@Component({
  selector: 'common-snackbar',
  templateUrl: './snackbar.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SnackbarComponent {
  protected data = inject<NotificationSnackbarData>(MAT_SNACK_BAR_DATA) ?? {
    message: 'Hello There!'
  };

  static forSuccess(snackbar: MatSnackBar) {
    snackbar.openFromComponent(SnackbarComponent, SuccessSnackbarDefaults);
  }

  static forError(snackbar: MatSnackBar, error: unknown) {
    let data: NotificationSnackbarData;

    switch (true) {
      case error instanceof ProblemDetails:
        data = {
          message: error.type
        };
        break;
      case error instanceof Error:
        data = {
          message: error.message
        };
        break;
      default:
        data = {
          message: 'unknown-error'
        };
    }

    snackbar.openFromComponent(this, {
      ...ErrorSnackbarDefaults,
      data
    });

    return error;
  }
}
export const ErrorSnackbarDefaults: MatSnackBarConfig = {
  ...new MatSnackBarConfig(),
  panelClass: 'error-snackbar',
  duration: 3000,
  data: {
    message: 'An error has occured. Please try again.'
  }
};

export const SuccessSnackbarDefaults: MatSnackBarConfig = {
  ...new MatSnackBarConfig(),
  panelClass: 'success-snackbar',
  duration: 3000,
  data: {
    message: 'Success!'
  }
};

export const WarnSnackbarDefaults: MatSnackBarConfig = {
  ...new MatSnackBarConfig(),
  panelClass: 'warn-snackbar',
  duration: 3000,
};
