import { InjectionToken, inject } from '@angular/core';
import { ORIGIN } from '@dd/angular-common';
import { ProblemDetails } from '@plutus-realty/models/problem-details';
import type { ApiTrpcRouter } from '@plutus-realty/trpc/api';
import { TRPCClientError, TRPCLink, createTRPCClient, httpLink } from '@trpc/client';
import { observable } from '@trpc/server/observable';
import SuperJSON from 'superjson';
import { AuthService } from '../services/auth';
import { AUTH_READY } from '../services/auth.ready';

export const TRPC_API = new InjectionToken('TRPC Api', {
  providedIn: 'root',
  factory: () => {
    const api = new TrpcApi();
    return api.client;
  }
});


class TrpcApi {
  private readonly auth = inject(AuthService);
  private readonly ready$ = inject(AUTH_READY);

  private readonly headers = async () => {
    const token = await this.ready$
      .then(() => this.auth.token());

    const headers = new Headers();
    if (token)
      headers.set('authorization', `Bearer ${token}`);

    return headers;
  };

  private readonly url = `${inject(ORIGIN)}/api/trpc`;

  public readonly client = createTRPCClient<ApiTrpcRouter>({
    links: [
      authLinkFactory(this.auth),
      httpLink({
        url: this.url,
        headers: this.headers,
        methodOverride: 'POST',
        transformer: SuperJSON
      })
    ]
  });
}

export const trpcProblemDetails = (e: TRPCClientError<ApiTrpcRouter>) => {
  const pojo = e.data || (e.meta?.['responseJSON'] as ProblemDetails);
  let pd;
  try {
    pd = ProblemDetails.fromObject(pojo);
  }
  catch {
    pd = new ProblemDetails();
    pd.type = 'unknown-error';
    pd['error'] = pojo;
  }

  return pd;
};

export function authLinkFactory(auth: AuthService): TRPCLink<ApiTrpcRouter> {
  return () => ({ next, op }) => {
    return observable(observer => {
      return next(op).subscribe({
        next: observer.next,
        error: async err => {
          const pd = trpcProblemDetails(err);
          if (pd.type === 'invalid-bearer-token') {
            await auth.revokeToken();
          }
          observer.error(err);
        },
        complete: observer.complete
      });
    });
  };
}