<form [formGroup]="form" [id]="formId" #fgd="ngForm"
      (ngSubmit)="submit(fgd)"
      (reset)="reset()"
      class="@container grid grid-cols-2 gap-x-4">

  <div *ngrxLet="form.controls.name as control"
       [formFieldControl]="control"
       class="col-span-2">
    <label>Name</label>
    <input type="text" [formControl]="control" #input>
    @if (control.hasError('required')) { <span class="error">Required</span> }
    @if (control.hasError('maxlength')) { <span class="error">{{input.value.length || 0}}/{{maxlengths.name}}</span> }
    <span class="hint">{{input.value.length || 0}}/{{maxlengths.name}}</span>
  </div>

  <div *ngrxLet="form.controls.phone as control"
       [formFieldControl]="control"
       class="col-span-2 @md:col-span-1">
    <label>Phone</label>
    <input type="text" [formControl]="control" #input>
    @if (control.hasError('required')) { <span class="error">Required</span> }
    @if(control.hasError('zod')){<span class="error">{{control.errors?.['zod']}}</span>}
    <span class="hint">10-digit phone number</span>
  </div>

  <div *ngrxLet="form.controls.email as control"
       [formFieldControl]="control"
       class="col-span-2 @md:col-span-1">
    <label>Email</label>
    <input type="text" [formControl]="control" #input>
    @if (control.hasError('maxlength')) { <span class="error">{{input.value.length || 0}}/{{maxlengths.email}}</span> }
    <span class="hint">{{input.value.length || 0}}/{{maxlengths.email}}</span>
  </div>

  <div *ngrxLet="form.controls.subject as control"
       [formFieldControl]="control"
       class="col-span-2">
    <label>Subject / Requirement</label>
    <input type="text" [formControl]="control" #input>
    @if (control.hasError('required')) { <span class="error">Required</span> }
    @if (control.hasError('maxlength')) { <span class="error">{{input.value.length || 0}}/{{maxlengths.subject}}</span> }
    <span class="hint">{{input.value.length || 0}}/{{maxlengths.subject}}</span>
  </div>

  <div *ngrxLet="form.controls.message as control"
       [formFieldControl]="control"
       class="col-span-2">
    <label>Message (optional)</label>
    <div class="field">
      <textarea [formControl]="control" cdkTextareaAutosize class="min-h-20" #input></textarea>
    </div>
    @if (control.hasError('maxlength')) { <span class="error">{{input.value.length || 0}}/{{maxlengths.message}}</span> }
    <span class="hint">{{input.value.length || 0}}/{{maxlengths.message}}</span>
  </div>
</form>

<div class="flex gap-4 justify-center items-center">
  <button type="submit" [attr.form]="formId"
          class="filled-button"
          [disabled]="submitting$()"
          mat-ripple>
    <replace-spinner [showSpinner]="submitting$()" diameter="20">
      Get a Call/Email
    </replace-spinner>
  </button>
  <button type="reset" [attr.form]="formId"
          class="outlined-button"
          mat-ripple #resetButton>
    Clear
  </button>
</div>