import { AbstractControl } from '@angular/forms';
import { ZodString } from 'zod';

export const zodValidator = <T extends ZodString>(validator: T) => {
  return (control: AbstractControl) => {
    const { success, error } = validator.safeParse(control.value);

    if (success)
      return null;

    const issue = error.issues[0];

    return { zod: issue.message || issue.code };
  };
};