import { INJECTOR, Injectable, inject } from '@angular/core';
import { OAUTH_CLIENT_CONFIG, OAUTH_SERVER_CONFIG } from '../dependency-injection';
import { AuthService } from '../services/auth';
import { verifyOk } from './utilities';

@Injectable({
  providedIn: 'root'
})
export class OAuthApi {
  private readonly auth = inject(AuthService);
  private readonly injector = inject(INJECTOR);

  readonly authorize = (
    code: { challenge: string; method: string; },
    state?: string
  ) => {
    const config = this.injector.get(OAUTH_CLIENT_CONFIG);
    const url = new URL(config.origin + config.endpoints.authorize);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('code_challenge', code.challenge);
    url.searchParams.set('code_challenge_method', code.method);
    url.searchParams.set('redirect_uri', config.redirectUri);
    if (state)
      url.searchParams.set('state', state);

    location.href = url.href;
  };

  readonly token = (
    code: string,
    verifier: string
  ) => {
    const config = this.injector.get(OAUTH_CLIENT_CONFIG);

    const url = new URL(config.origin + config.endpoints.token);
    const body = JSON.stringify({
      client_id: config.clientId,
      grant_type: 'authorization_code',
      code,
      code_verifier: verifier,
    });
    const headers = {
      'content-type': 'application/json'
    };

    return fetch(url, { method: 'POST', body, headers })
      .then(verifyOk)
      .then<string>(r => r.json());

  };

  readonly generateCode = async (search: string) => {
    const config = inject(OAUTH_SERVER_CONFIG);

    const token = await this.auth.token();
    if (!token) {
      throw new Error('missing auth token');
    }

    const url = new URL(config.origin + config.endpoints.authorize);
    url.search = search;
    const headers = {
      authorization: `Bearer ${token}`
    };

    return fetch(url, { headers })
      .then(verifyOk)
      .then<string>(r => r.json())
      .catch(e => {
        if (e.type === 'invalid-token')
          this.auth.revokeToken();
        throw e;
      });
  };
}